import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import IntroSlider from "../container/IntroSlider/IntroSlider";
import Newsletter from "../container/Newsletter/Newsletter";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import Solution from "../container/Solution/Solution";
import ClientImages from "../container/Client/ClientImages";
import Impact from "../container/Impact/Impact";
import Process from "../container/Process/Process";

//   {
//     "id": 10,
//     "image": "images/client/ecb.png"
//   },
//   {
//     "id": 11,
//     "image": "images/client/omuthiya.png"
//   },
//   {
//     "id": 12,
//     "image": "images/client/nkurenkuru.jpg"
//   }

const Home = () => {
  return (
    <React.Fragment>
      <SEO title="LOTS" />
      <Header />
      <IntroSlider />
      <Process />
      <Solution />
      <Impact />
      <ClientImages classOption="section-padding-bottom" />
      <Newsletter />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Home;
