import React from 'react'
import { NavLink } from 'react-router-dom';

const NavBar = () => {


    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink
                        to={process.env.PUBLIC_URL + "/"}>
                        <span className="menu-text">Home</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeClassName="active-link"
                        to={process.env.PUBLIC_URL + "/about"}

                    >
                        <span className="menu-text">About Us</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeClassName="active-link"
                        to={process.env.PUBLIC_URL + "/industry"} >
                        <span className="menu-text">Industries</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeClassName="active-link"
                        to={process.env.PUBLIC_URL + "/function"}>
                        <span className="menu-text">Functions</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeClassName="active-link"
                        to={process.env.PUBLIC_URL + "/solution"}>
                        <span className="menu-text">Solutions</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeClassName="active-link"
                        to={process.env.PUBLIC_URL + "/contact"} >
                        <span className="menu-text">Contact Us</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
