import React, { Fragment, useState } from 'react';
import { escape } from 'lodash';
import { SucessSnackbar, WarningSnackbar } from '../Snackbar/Snackbar';

const ProjectForm = () => {

    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState(false)

    const API_PATH = "https://lotsinsights.com/php/lots.php?";
    let contactForm = "";
    let _fullname = "";
    let _email = "";
    let _subject = "";
    let _message = ""

    const postMail = (path) => {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                setSuccess(true);
            }
            if (this.readyState === 4 && this.status === 500) {
                setWarning(true);
            }
        };
        xhttp.open("GET", path, true);
        xhttp.send();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const recipient = "sales@lotsinsights.com";
        const alt_recipient = "solutions@lotsinsights.com";
        const fullname = _fullname.value
        const email = _email.value;
        const subject = _subject.value;
        const message = _message.value;

        const url =
            `fullname=${escape(fullname)}
        &email=${escape(email)}
        &subject=${escape(subject)}
        &message=${escape(message)}
        &recipient=${escape(recipient)}
         &alt_recipient=${escape(alt_recipient)}`;
        postMail(API_PATH + url);

        contactForm.reset();
    };


    return (
        <Fragment>
            <form ref={(form) => (contactForm = form)} onSubmit={onSubmit} autoComplete="off">
                <div className="row mb-n4">

                    <div className="col-md-12 col-12 mb-4">
                        <input
                            required
                            type="text"
                            placeholder="Your Name *"
                            name="name"
                            ref={(input) => (_fullname = input)}
                        />
                    </div>
                    <div className="col-md-12 col-12 mb-4">
                        <input
                            required
                            type="email"
                            placeholder="Email"
                            name="email"
                            ref={(input) => (_email = input)}
                        />
                    </div>

                    <div className="col-md-12 col-12 mb-4">
                        <input
                            required
                            type="text"
                            placeholder="Subject"
                            name="subject"
                            ref={(input) => (_subject = input)}
                        />
                    </div>

                    <div className="col-12 mb-6">
                        <textarea
                            required
                            name="message"
                            placeholder="Message..."
                            ref={(input) => (_message = input)}
                        >
                        </textarea>
                    </div>

                    <div className="col-12 text-center mb-4">
                        <button
                            className="btn" type='submit'
                        >Submit</button>
                    </div>

                </div>
            </form>
            <p className="form-messege"></p>
            <SucessSnackbar
                open={success}
                msg=" Message sent !"
                onClose={() => setSuccess(false)} />
            <WarningSnackbar
                open={warning}
                msg=" Failed, try again!"
                onClose={() => setWarning(false)} />

        </Fragment>
    )
}

export default ProjectForm;
