import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';


const MainSearch = ({ show, onClose }) => {
    return (
        <div className={`main-search-active ${show ? "inside" : ""}`}>
            <div className="sidebar-search-icon">
                <button className="search-close" onClick={onClose}><i className="pe-7s-close"></i></button>
            </div>
            <div className="sidebar-search-input">
                <form autoComplete='off'>
                    <div className="form-search">
                        <input id="search" className="input-text" placeholder="" type="search" />
                        <button>
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </form>
                <p className="form-description">Hit enter to search</p>
            </div>
        </div>
    )
}

MainSearch.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func
}

export default MainSearch;






export function SearchData() {

    const people = [
        "Siri",
        "Alexa",
        "Google",
        "Facebook",
        "Twitter",
        "Linkedin",
        "Sinkedin"
    ];

    const [searchTerm, setSearchTerm] = useState("");

    const [searchResults, setSearchResults] = useState([]);
    const handleChange = e => {
        setSearchTerm(e.target.value);
    };


    useEffect(() => {
        const results = people.filter(person =>
            person.toLowerCase().includes(searchTerm)
        );
        setSearchResults(results);
    }, [searchTerm]);

    return (
        <div className="App">
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
            />
            <ul>
                {searchResults.map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
        </div>
    );
}