import React from 'react';
import { Link } from "react-router-dom";
import SectionTitleTwo from '../../SectionTitles/SectionTitleTwo';

export const BondsDetails = () => {
    return (
        <>
            <div className="section section-padding-top section-padding-bottom">
                <div className="container">
                    <div className="row  pb--80">
                        <div className="row mt-lg-20">
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="digital-marketing" data-aos="fade-up">
                                    <SectionTitleTwo
                                        title="Our expertise"
                                    />
                                </div>
                                <div className="details-list">
                                    <li>Customer management</li>
                                    <li>Stakeholder Management</li>
                                    <li>Bond Sales Process Management</li>
                                    <li>Document Management</li>
                                    <li>Client Communications</li>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                                <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                    <div className="inner">
                                        <p>The process of owning a home is too complicated and we believe it can be simpler.
                                            We believe home and land ownership is a fundamental human right. We help all
                                            stakeholders realize this in an efficient and frictionless manner using our process
                                            engineering skills and tools.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="For homeowners"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">No stress</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>From designing your dream home to getting your documents ready
                                        for bank applications, we help you manage the process stress-free.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Faster bond approval</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>We facilitate homeowners with the loan approval process, and help
                                        get the best mortgage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">
                                    Visibility and communication</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        The current loan process is ruled by ambiguity and lack of visibility, we help homeowners with
                                        total insights on the entire process and maintain a deep level of communication
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Professional project and process management</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>After your loan is approved we manage your entire home building process from material procurement to furnishing
                                        and we ensure professional management of all stakeholders from banks to contractors.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* for developers */}
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="For developers"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Secure project finance (Letter of undertaking to the happy letter)</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>With our bond processing capabilities, we help developers secure project financing through financial
                                        facilitation and client acquisition</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Improve sales turnaround time (Deed of sale to Letter of Undertaking)</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>We power your sales team and real estate agents with back-office solutions to help them sell more.
                                        LOTS focuses on
                                        improving your sales turnaround time and ensures your marketing and sales acquire more deeds of sales.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Efficient communication and stakeholder management (HI TO GOODBYE)</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>Keeping clients happy and informed is a business on its own. You are a developer, with a focus on
                                        developing homes. We help you
                                        just do that. Forget the daily calls, SMS, emails, and stakeholder management, we handle all of that.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Capital turnaround management (Startup cash to profit)</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>LOTS has your best interest at heart; thus, our focus is ensuring your cash commitment generates
                                        the desired profit
                                        margins. We do this by tracking your capital flow across each secured housing unit.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* for banks and converyers */}
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="For banks and conveyancers"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Quality applications</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>We ensure your Loan Officer has the right information, compliant data, and affordability checks before commencing the application process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Increase Efficiency</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>We help reduce friction in the bond/mortgage process by improving and automating workflows without adding more resources.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-20 mt-12">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <h5 className="heading heading-h5">Faster loan cycle</h5>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-0 mt-4" data-aos="fade-up">
                                <div className="inner">
                                    <p>We help you reduce your loan cycle time per bond as fast as 3 weeks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="work-btn mt-5">
                        <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + 'solution'}>Back</Link>
                    </div>
                </div>
            </div>
        </>
    )
}


