import PropTypes from "prop-types";
import React from 'react';
import BrandData from '../../data/brand/brand.json';
import Brand from '../../components/Brand/Brand.jsx';
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';


const ClientImages = ({ classOption }) => {

    SwiperCore.use([Autoplay, Navigation])

    const swiperOption = {
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        slidesPerView: 6,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.brand-slider .swiper-button-next',
            prevEl: '.brand-slider .swiper-button-prev',
        },
    }

    return (
        <div className={`brand-section section ${classOption}`}>
            <div className="container">
                <div className="row">
                    <div className="col mb-6" data-aos="fade-up">
                        <div className="faq-content">
                            <SectionTitleTwo
                                title="Our Clients"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12" data-aos="fade-up">
                        <div className="brand-wrapper">
                            <div className="brand-list">
                                <Swiper effect="cube" className="brand-slider"
                                    {...swiperOption}

                                >
                                    {BrandData &&
                                        BrandData.map((single, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <Brand data={single} key={key} />
                                                </SwiperSlide>
                                            );
                                        })}
                                    <div className="brand-slider-next brand-slider-nav swiper-button-next">
                                        <i className="fal fa-angle-right"></i>
                                    </div>
                                    <div className="brand-slider-prev brand-slider-nav swiper-button-prev">
                                        <i className="fal fa-angle-left"></i>
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ClientImages.propTypes = {
    classOption: PropTypes.string
};
ClientImages.defaultProps = {
    classOption: "brand-section section section-padding-bottom"
};

export default ClientImages;
