import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import StrategyBoxData from '../../data/solution/strategyDetails.json';
import IconBox from '../../components/IconBox/IconBox.jsx';
import Parallax from 'parallax-js';
import { Link } from "react-router-dom";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";

export const StrategyIconBox = ({ classOption }) => {

    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })
        parallaxInstance.enable();
        return () => parallaxInstance.disable();
    }, [])

    return (
        <div className={`section section-padding-bottom ${classOption}`}>
            <div className="container">
                <div className="digital-marketing" data-aos="fade-up">
                    {/* <h3 className="heading heading-h3 mb-5">How do we help?</h3>
                    */}
                    <SectionTitleTwo
                        title="How we help"
                    />
                </div>
                <div className="row row-cols-lg-12 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-box-shape-animation">
                    {StrategyBoxData && StrategyBoxData.map((data, key) => {
                        return (
                            <div key={key} className="col mb-6" data-aos="fade-up" data-aos-delay="300">
                                <IconBox classOption="box-border" data={data} key={key} />
                            </div>
                        );
                    })}

                    <div className="shape shape-1" id="scene" ref={sceneEl}>
                        <span data-depth="1"><img src={process.env.PUBLIC_URL + "/images/shape-animation/video-shape-1.png"} alt="" /></span>
                    </div>
                </div>
                <div className="work-btn mt-5">
                    <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + 'solution'}>Back</Link>
                </div>
            </div>
        </div>
    )
}

StrategyIconBox.propTypes = {
    classOption: PropTypes.string,
};
StrategyIconBox.defaultProps = {
    classOption: "section section-padding-t90 section-padding-bottom"
};