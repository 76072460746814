import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const DigitalICT = () => {

    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-in-up" data-aos-delay="300">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                title="Digital and Information Communication Technology"
                            />
                            <p data-aos="fade-right" data-aos-delay="300">We help our clients harness technology and data to
                                accelerate their business to unprecedented heights.
                                By engineering and delivering technology and data solutions we help clients acquire new
                                capabilities that provide a cutting edge to execution.
                            </p>
                            <div className="info pt-3" data-aos="fade-left" data-aos-delay="300">
                                <h5>David Ananias</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/team/dave.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DigitalICT;
