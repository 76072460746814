import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">Technology Solutions</AccordionTitle>
                    <AccordionContent id="one">To achieve simplicity an organization&apos;s operations need to be fluid and optimized. LOTS systems are custom made and not off the shelf this makes implementation easy.
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Managed Change</AccordionTitle>
                    <AccordionContent id="two">We understand that culture eats strategy for lunch (or breakfast) we believe in the soft elements of business, our change strategies allow harmony between strategy and operations.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">Strategy Execution</AccordionTitle>
                    <AccordionContent id="three">“If you can&apos;t measure it, you can&apos;t manage it.” Peter Drucker.
                        LOTS strategy focuses on strategy formulation,  deployment, and objective measurement. We believe in dynamic and simplified strategies that are measurable.
                        .</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
