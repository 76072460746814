import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle.jsx';
import TeamData from '../../data/team/executive.json';
import SingleTeam from '../../components/Team/SingleTeam.jsx';

const ExecutiveTeam = () => {
    return (
        <div className="section section-padding">
            <div className="container">
                <SectionTitle
                    titleOption="text-center mb-12"
                    title="Executive & Leadership"
                />

                <div className="row row-cols-lg-4 row-cols-sm-2 row-cols-1 mb-n6">
                    {TeamData && TeamData.map((single, key) => {
                        return (
                            <div key={key} className="col mb-6" data-aos="zoom-in" data-aos-delay="300">
                                <SingleTeam data={single} key={key} />
                            </div>
                        );
                    })}
                </div>


            </div>
        </div>
    )
}

export default ExecutiveTeam;
