import { useState, useEffect, useRef } from "react";
import Tilt from 'react-parallax-tilt';
import { Link } from "react-router-dom";
import Parallax from 'parallax-js';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';

const BondsFinancials = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    return (
        <div className="section section-padding-bottom section-padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5 col-12" data-aos="zoom-in-right" data-aos-delay="400">
                        <div className="about-image-area">
                            <div className="about-image pt-lg-10">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/solution/bond.jpg"} alt="" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                title="Bonds and Financial Facilitation"
                            />
                            <div>
                                <h6>For Homeowners</h6>
                                Owning a home is a complex and daunting process, particularly for the first-time homeowner.
                                We make the home-ownership
                                process seamless and pain-free by managing the experience from concept to hand-over
                            </div>
                            <div className="pt-8">
                                <h6>For Developers</h6>
                                Commercial developments require a multi-skilled team from engineering to legal. With LOTS Bonds,
                                we power the property developer&apos;s operations with process management capabilities
                                to streamline the bond sales process. We manage all parties in each transaction from customer to municipality.
                            </div>
                            <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-2 mb-3" to={process.env.PUBLIC_URL + "/bonds-and-financials"}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BondsFinancials;
