import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';

const CorePillars = () => {
    return (
        <div className="cta-section section section-padding-bottom">
            <div className="container text-center pillars-icon-up-down-animation">
                <SectionTitle
                    headingOption="fz-34"
                    title="Our Core Pillars"
                />

                <div className='pillar-icon'>
                    <div className="shape shape-1">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/pillar/simple.svg"} alt="" />
                            <p>Simplicity</p>
                        </span>
                    </div>
                    <div className="shape shape-2">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/pillar/creativity.svg"} alt="" />
                            <p>Creativity</p>
                        </span>
                    </div>
                    <div className="shape shape-3">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/pillar/empathy.svg"} alt="" />
                            <p>Care and Empathy</p>
                        </span>
                    </div>
                    <div className="shape shape-4">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/pillar/excellence.svg"} alt="" />
                            <p>Quality and Excellence</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorePillars;
