import React from 'react';
import SectionTitleTwo from '../../SectionTitles/SectionTitleTwo';

export const StrategyDetailsContainer = () => {
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Why strategy consulting?"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>LOTS crafts strategies because we want to create organizational
                                        value and transform organizations towards economic and social
                                        success. We believe Strategy is everything and beats the unpredictable
                                        and disruptive nature of business. By combining experience, tools,
                                        frameworks, and data insights we deliver empirical strategies that
                                        set your organization apart.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


{/* <h3 className="heading heading-h3">Why strategy consulting?</h3> */ }
{/* <p>To deliver an organization&apos;s strategy, the strategy must be
                                        automated, executable, and measurable. This is how we approach business
                                        strategy by focusing not on design but simplicity, implementation, and
                                        performance tracking.</p> */}

{/* <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h3 className="heading heading-h3">Why we do it?</h3>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                <p>LOTS crafts strategies because we want to create organizational
                                    value and transform organizations towards economic and social
                                    success. We believe Strategy is everything and beats the unpredictable
                                    and disruptive nature of business. By combining experience, tools,
                                    frameworks, and data insights we deliver empirical strategies that
                                    set your organization apart.</p>
                            </div>
                        </div>
                    </div>
                </div> */}

{/* <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
                        <div className="work-left work-details mt-6">
                            <div className="work-main-info">
                                <div className="work-content">
                                    <h3 className="heading heading-h3">Why strategy consulting?</h3>
                                    <div className="desc mt-8">
                                        <div className="content mb-5" data-aos="fade-up">
                                            <p>We strategize and plan to shape the very fabric and future of organizations through executable strategies. We Deliver Business Engineering as a Solution.</p>
                                            <p>To deliver an organization&apos;s strategy, the strategy must be automated, executable, and measurable. This is how we approach business strategy by focusing not on design but simplicity, implementation, and performance tracking.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

{/* <div className="col-lg-4 col-md-6 col-12">
                        <div className="work-left work-details" data-aos="fade-up">
                            <div className="portfolio-main-info">
                                <h2 className="title"></h2>
                                <div className="work-details-list mt-12">
                                </div>
                                <div className="work-share pt--70 pt_md--40 pt_sm--40">
                                    <h3 className="heading heading-h3">Our approach</h3>
                                    <div className="details-list">
                                        <li>Audit</li>
                                        <li>Strategize</li>
                                        <li>Execute</li>
                                        <li>Transform</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}