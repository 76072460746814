import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/CallToAction/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { BondsDetails } from '../components/Solution/solutionDetailsContainer/BondsDetails';
import { StrategyDetailsContainer } from '../components/Solution/solutionDetailsContainer/StrategyDetailsContainer';
import { OperationDetailsContainer } from '../components/Solution/solutionDetailsContainer/OperationDetailsContainer';
import { CustomerInteractiveDetails } from '../components/Solution/solutionDetailsContainer/CustomerInteractiveDetails';
import { TechnologyDataDetails } from '../components/Solution/solutionDetailsContainer/TechnologyDataDetails'
import { CustomerIconBox } from '../components/IconBox/CustomerIconBox';
import { OperationIconBox } from '../components/IconBox/OperationIconBox';
import { StrategyIconBox } from '../components/IconBox/StrategyIconBox';
import { TechDataIconBox } from '../components/IconBox/TechDataIconBox'

export const StrategyDetails = () => {

    return (
        <React.Fragment>
            <SEO title="LOTS | Strategy-consulting" />
            <Header />
            <Breadcrumb
                image="images/background/solution.jpg"
                title="Strategy and Consulting"
                content="Home"
                contentTwo="Solution Strategy Consulting"
            />
            <StrategyDetailsContainer />
            <StrategyIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export const OperationDetails = () => {

    return (
        <React.Fragment>
            <SEO title="LOTS | Operations-outsourcing" />
            <Header />
            <Breadcrumb
                image="images/background/industry.jpg"
                title="Operations and Outsourcing"
                content="Home"
                contentTwo="Solution , Operations and Outsourcing"
            />
            <OperationDetailsContainer />
            <OperationIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export const CustomerDetails = () => {

    return (
        <React.Fragment>
            <SEO title="LOTS | Customer Interactive" />
            <Header />
            <Breadcrumb
                image="images/background/function.jpg"
                title="Customer Interactive and Business Design"
                content="Home"
                contentTwo="Solution , Customer interactive"
            />
            <CustomerInteractiveDetails />
            <CustomerIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export const TechDataDetails = () => {

    return (
        <React.Fragment>
            <SEO title="LOTS | Technology & Data" />
            <Header />
            <Breadcrumb
                image="images/background/function.jpg"
                title="Business Technology and Engineering"
                content="Home"
                contentTwo="Solution , Technology and Data"
            />
            <TechnologyDataDetails />
            <TechDataIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export const BondsFinancialsDetails = () => {

    return (
        <React.Fragment>
            <SEO title="LOTS | Bonds & Financials" />
            <Header />
            <Breadcrumb
                image="images/background/solution.jpg"
                title="Bonds and Financial Facilitation"
                content="Home"
                contentTwo="Solutions , Bonds and Financials"
            />
            <BondsDetails />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}