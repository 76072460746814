import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import { Link } from "react-router-dom";

const Process = () => {
    return (
        <div className="cta-section section section-padding-top">
            <div className="container text-center icon-up-down-animation" data-aos="zoom-in-right" data-aos-delay="300">
                <SectionTitle
                    headingOption="fz-34"
                    title="Our Approach"
                    subTitle="Our team of engineers, designers, developers, and strategists are idealists who love what they do."
                />

                <div className='flex-icons'>
                    <div className="shape shape-3">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/audit.svg"} alt="" />
                            <p>Audit</p>
                        </span>
                    </div>
                    <div className="shape shape-1">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/strategy.svg"} alt="" />
                            <p>Strategize</p>
                        </span>
                    </div>
                    <div className="shape shape-2">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/design.svg"} alt="" />
                            <p>Design</p>
                        </span>
                    </div>
                    <div className="shape shape-4">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/engineer.svg"} alt="" />
                            <p>Engineer</p>
                        </span>
                    </div>
                    <div className="shape shape-5">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/experience.svg"} alt="" />
                            <p>Experience</p>
                        </span>
                    </div>
                    <div className="shape shape-6">
                        <span>
                            <img src={process.env.PUBLIC_URL + "images/icons/empower.svg"} alt="" />
                            <p>Empower</p>
                        </span>
                    </div>
                </div>
                <div className='mt-15'>
                    <Link className="btn" to={process.env.PUBLIC_URL + "/contact"} data-aos="fade-up" data-aos-delay="300">Contact Us</Link>

                </div>

            </div>
        </div>
    )
}

export default Process;



