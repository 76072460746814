import PropTypes from "prop-types";
import React from 'react';
// import ReactVivus from 'react-vivus';

const IconBox = ({ data, classOption }) => {
    return (
        <div className={`icon-box text-center ${classOption}`} key={data.id}>
            <div className="content">
                <h3 className="title">{data.title}</h3>
                <div className="desc">
                    <p>{data.text}</p>
                </div>
            </div>
        </div>
    )
}

IconBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

IconBox.defaultProps = {
    classOption: "icon-box text-center",
};

export default IconBox;
