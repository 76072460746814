import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SectionTitle from '../../components/SectionTitles/SectionTitle.jsx';

const TimelineStory = () => {
  return (
    <div className="section section-padding">
      <div className="container">
        <SectionTitle
          titleOption="text-center mb-12"
          title="Timeline Story"
        />

        <div className="row row-cols-lg-4 row-cols-sm-2 row-cols-1 mb-n6">
          <VerticalTimeline>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              date="2021 - present"
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}
            >
              <p>LOTS aims to be the leading solution engineering and company building firm in SADC. Name change to Legion Business Operations Consulting & Technologies (PTY) LTD.
              </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              date="October  2020"
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}

            >
              <p>LOTS ceased being a subsidiary of Lithon Holdings and seek to operate independently.</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              date="2019 - 2020"
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}
            >
              <p>LOTS operates as a subsidiary of Lithon Holdings. Owned 50% by Abisai and 50% by Lithon Holdings.</p>
            </VerticalTimelineElement>


            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="November 2018"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}
            >
              <p>Abisai founds Lithon Organisation Consulting and Technologies (LOTS) to focus on management consulting and technology as a subsidiary of Lithon Holdings.</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="October 2018"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}
            >
              <p>Lithon Industrial Engineering Division closed and all staff retrenched.</p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="September 2017"
              contentStyle={{ boxShadow: "0 20px 40px rgba(0,0,0,.07)" }}
              contentArrowStyle={{ borderRight: "7px solid  #fe0040" }}
              icon={<img src={process.env.PUBLIC_URL + "images/lotsicon.png"} alt="" />}

            >
              <p>Abisai joined Lithon Industrial Engineering Division as a Sub-Consultant.</p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>


      </div>
    </div>


  );
}
export default TimelineStory