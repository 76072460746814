import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const OfficeStrategy = () => {

    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                title="Office of the MD"
                            />
                            <p data-aos="fade-right" data-aos-delay="300">
                                At LOTS we believe strategies are limitless, however enduring strategies are finite.
                                We help organizations define and deliver through systematically crafted strategies that
                                create organizational value across all levels. By delivering curated and enduring
                                strategies we help organization leaders, CEOs, Managing Members, and Managing Directors
                                succeed effortlessly.
                            </p>
                            <div className="info pt-3" data-aos="fade-left" data-aos-delay="300">
                                <h5>Abisai Ndeunjema</h5>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/AB.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfficeStrategy;
