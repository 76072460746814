import React from 'react';
import Logo from '../../components/logo/Logo';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';

const Footer = () => {

    const year = new Date().getFullYear()

    return (
        <div className="footer-section section footer-bg-color">
            <div className="container">
                <div className="row mb-lg-2 mb-md-3">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Logo
                                    image={`${process.env.PUBLIC_URL}/images/logo/lotswhite.png`}
                                />
                            </div>
                            <div className="footer-widget-content">
                                <div className="content">
                                    <p><a href="tel:(+264)833301830">(+264) 83 330 1830</a></p>
                                    <p><a href="mailto:solutions@lotsinsights.com">solutions@lotsinsights.com</a></p>
                                    <a href="https://www.linkedin.com/company/legion-business-operations-consulting-and-technologies/mycompany/"
                                        target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {FooterData && FooterData.map((single, key) => {
                        return (
                            <div key={key} className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <FooterLinkItem data={single} key={key} />
                            </div>
                        );
                    })}
                </div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">&copy; {year} <strong>Legion Business Operations Consulting & Technologies (PTY) LTD</strong>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
