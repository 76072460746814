import React from 'react';
import SEO from '../components/SEO';
import ScrollToTop from '../components/ScrollToTop.jsx';
import { Link } from 'react-router-dom';

const ComingSoon = () => {
    const publicUrl = process.env.PUBLIC_URL;
    return (
        <React.Fragment>
            <SEO title="LOTS | Coming soon" />
            <div className='coming' style={{
                backgroundImage: `url(${publicUrl}/images/video/comingsoon.jpg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <video className="video" playsinline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                    <source src={process.env.PUBLIC_URL + "images/video/bg.mp4"} alt="" />
                </video>
                <div className="coming-soon">
                    <div className="coming-soon-content">
                        <div>
                            <h3 className="title" >Page under construction</h3>
                            <p>
                                We&apos;re working hard to finish the development of this interesting page.</p>
                        </div>
                        <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-2 mb-3"
                            to={process.env.PUBLIC_URL + "/"}>Back</Link>
                    </div>
                </div>
                <div className="social-icons">
                    <div className="d-flex flex-row flex-lg-column justify-content-center align-items-center h-100 mt-3 mt-lg-0">
                        <a className="btn btn-dark m-3" href="https://www.linkedin.com/company/legion-business-operations-consulting-and-technologies/mycompany/">
                            <i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </React.Fragment>
    )
}
export default ComingSoon;