import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/CallToAction/Breadcrumb/Breadcrumb';
import CallToAction from '../container/CallToAction/CallToAction';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MarketingSales from '../container/Function/MarketingSales';
import OfficeStrategy from '../container/Function/OfficeStrategy';
import DigitalICT from '../container/Function/DigitalICT';
import Operations from '../container/Function/Operations';
import HumanCapital from '../container/Function/HumanCapital';


const Function = () => {
    return (
        <React.Fragment>
            <SEO title="LOTS | Functions" />
            <Header />
            <Breadcrumb
                image="images/background/functions.jpg"
                title="Functions"
                content="Home"
                contentTwo="Functions"
            />
            <OfficeStrategy />
            <MarketingSales />
            <DigitalICT />
            <Operations />
            <HumanCapital />
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Function;