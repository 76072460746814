import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/CallToAction/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import StrategyConsulting from '../components/Solution/StrategyConsulting';
import OperationOtsourcing from '../components/Solution/OperationOutsourcing';
import CustomerInteractive from '../components/Solution/CustomerInteractive';
import TechnologyData from '../components/Solution/TechnologyData';
import BondsFinancials from '../components/Solution/BondsFinancials';
import CallToAction from '../container/CallToAction/CallToAction';


const Function = () => {
    return (
        <React.Fragment>
            <SEO title="LOTS | Solutions" />
            <Header />
            <Breadcrumb
                image="images/background/mision.jpg"
                title="Solutions"
                content="Home"
                contentTwo="Solutions"
            />
            <StrategyConsulting />
            <OperationOtsourcing />
            <CustomerInteractive />
            <TechnologyData />
            <BondsFinancials />
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Function;