import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import BoardTeam from '../container/Team/BoardTeam';
import ExecutiveTeam from '../container/Team/ExecutiveTeam';
import OperationTeam from '../container/Team/OperationTeam';
import TimelineStory from '../container/Team/TimelineStory';
import CorePillars from '../container/Team/CorePillars'
import VisionMisionSlider from '../container/IntroSlider/VisionMisionSlider';




const AboutUs = () => {
    return (
        <React.Fragment>
            <SEO title="LOTS | About Us" />
            <Header />
            <VisionMisionSlider />
            <ExecutiveTeam />
            <OperationTeam />
            <BoardTeam />
            <TimelineStory />
            <CorePillars />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default AboutUs;



