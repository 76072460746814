import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import industryData from "../../data/industry/industries.json"
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const IndustryComponent = ({ data }) => {

    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })
        parallaxInstance.enable();
        return () => parallaxInstance.disable();
    }, [])

    return (
        <div className="section section-padding-top section-padding-bottom section-padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-image-area">
                            <div className="about-image pt-lg-8">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + data.image} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img src={process.env.PUBLIC_URL + data.animImage} alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                title={data.title}
                            />
                            <div>
                                {data.content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

IndustryComponent.propTypes = {
    data: PropTypes.object
};


const Industries = () => {
    return (
        <div>
            {industryData &&
                industryData.map((single, key) => {
                    return (
                        <div key={key}>
                            <IndustryComponent data={single} key={key} />
                        </div>
                    );
                })}
        </div>


    )
}

export default Industries






