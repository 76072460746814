import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/CallToAction/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import CallToAction from '../container/CallToAction/CallToAction';
import Industries from '../components/Industry/IndustryComponent';


const Industry = () => {
    return (
        <React.Fragment>
            <SEO title="LOTS | Industries" />
            <Header />
            <Breadcrumb
                image="images/background/background1.jpg"
                title="Industries"
                content="Home"
                contentTwo="Industries"
            />
            <Industries />
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Industry;



