import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const HumanCapital = () => {

    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            <SectionTitleTwo
                                title="People"
                            />
                            <p data-aos="fade-right" data-aos-delay="300">
                                There is a monumental global shift in the way we work. We help organizations manage the revolution in
                                the workforce, workplace, and organizational structure. By assisting in delivering organization design
                                solutions, we help clients manage work structure transformation through change control and strategic
                                people management.
                            </p>
                            <div className="info pt-3" data-aos="fade-left" data-aos-delay="300">
                                <h5>Justina Ambuga</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/team/Justine.png"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HumanCapital;
