import PropTypes from "prop-types";
import React from 'react';

const Brand = ({ data }) => {
    return (
        <div className="brand">
            <span>
                <img src={process.env.PUBLIC_URL + data.image} alt="logo image"/>
            </span>
        </div>
    )
}

Brand.propTypes = {
    data: PropTypes.object
}



export default Brand;
