import { useState, useEffect, useRef } from "react";
import Tilt from 'react-parallax-tilt';
import { Link } from "react-router-dom";
import Parallax from 'parallax-js';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';

const TechnologyData = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    return (
        <div className="section section-padding-bottom section-padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5 col-12" data-aos="zoom-in-right" data-aos-delay="400">
                        <div className="about-image-area">
                            <div className="about-image pt-lg-10">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/solution/coding.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                title="Technology and Data"
                            />
                            <div> We help clients build digital solutions that drive their business forward, from vendor solutions to
                                custom
                                solutions. You cannot separate technology and data, to create an intelligent business, we help you
                                harmonize technology and data among the complexity of deployment. We source and partner with
                                technology vendors, engineer, and design custom applications, build
                                technology infrastructure to solve the most critical technology challenges that drive organizations
                                forward.
                            </div>
                            <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-2 mb-3" to={process.env.PUBLIC_URL + "/tech-and-data"}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnologyData;
