import React from 'react';
import SectionTitleTwo from '../../SectionTitles/SectionTitleTwo';

export const OperationDetailsContainer = () => {
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Operations and outsourcing"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        Empower your business with productivity and get things done. Reduce costs
                                        and be more efficient with LOTS Operations and Outsourcing.
                                        With LOTS Operations and Outsourcing (Business Processing Engine),
                                        you get to focus on the big picture, leaving the basic tasks to us.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Why do we do it?"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>We empower SMEs, Startups, and Enterprises to operate, automate and build superfluid business
                                        processes using our powerful Back-Office Processing Engine which combines people and technology.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Why should you automate and outsource?"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>Growing and managing a business is a worthwhile effort, but it is hard. Few Namibian businesses grow
                                        from small businesses to well- governed enterprises. Also, growth comes with increased costs, higher
                                        payroll, fewer margins, and a big list of daily activities and initiatives. Business processes become
                                        more complex and ineffective. As a business executive, you are faced with executing most if not
                                        all on your own.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="How does it work?"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        With our functional experts and technology-powered Business Process Engine, we help you build an
                                        efficient business system, grow your revenues, buy you time, and make your business profitable.
                                        The LOTS Team helps with the jobs no one seems to get right, and we do it objectively and professionally.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}