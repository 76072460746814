import { useState, useEffect, useRef } from "react";
import { Progress } from 'reactstrap';
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';



const Impact = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top success-section-padding-bottom-180">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="zoom-in-right" data-aos-delay="300">
                        <div className="about-image-area right-0 skill-image-area">
                            <div className="about-image pt-lg-8 js-tilt">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/home/impact/chess.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                    <div className="offset-lg-1 col-lg-5" data-aos="fade-up" data-aos-delay="300">
                        <div className="success-content mt-lg-0 mt-md-50 mt-sm-50 mt-40">
                            <SectionTitleTwo
                                title="Our Impacts"
                            />
                            <div className="progress-bar--one">
                                <div className="progress-charts">
                                    <h6 className="heading ">Strategy Execution</h6>
                                    <div className="single-progress">
                                        <Progress
                                            barClassName="gradient-1"
                                            value="90" >
                                            <span className="percent-label">90%</span>
                                        </Progress>
                                    </div>
                                </div>
                                <div className="progress-charts ">
                                    <h6 className="heading ">Process Automation</h6>
                                    <div className="single-progress">
                                        <Progress barClassName="gradient-2" value="86" >
                                            <span className="percent-label">86%</span>
                                        </Progress>
                                    </div>
                                </div>
                                <div className="progress-charts ">
                                    <h6 className="heading ">Technology Deployment</h6>
                                    <div className="single-progress">
                                        <Progress barClassName="gradient-3" value="86" >
                                            <span className="percent-label">86%</span>
                                        </Progress>
                                    </div>
                                </div>
                                <div className="progress-charts ">
                                    <h6 className="heading ">Client Transformation</h6>
                                    <div className="single-progress">
                                        <Progress barClassName="gradient-4" value="91" >
                                            <span className="percent-label">91%</span>
                                        </Progress>
                                    </div>
                                </div>
                                <div className="progress-charts ">
                                    <h6 className="heading ">Economic Impact</h6>
                                    <div className="single-progress">
                                        <Progress barClassName="gradient-4" value="81" >
                                            <span className="percent-label">81%</span>
                                        </Progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Impact;
