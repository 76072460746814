import React from 'react'
import { Snackbar, Slide, Alert } from '@mui/material';

function SlideTransition(props: any) {
    return <Slide {...props} direction="up" />;
}

interface Props {
    open: boolean;
    onClose: () => void;
    msg?: any;
}

export const SucessSnackbar = (props: Props) => {
    const { open, onClose, msg } = props;

    const vertical = 'bottom';
    const horizontal = 'center';

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={3000}
            open={open}
            onClose={onClose}
            TransitionComponent={SlideTransition}
            className="snackbar"
        >
            <Alert className="successalert" severity="success">
                {msg}
            </Alert>
        </Snackbar >

    );
}

export const WarningSnackbar = (props: Props) => {
    const { open, onClose, msg } = props;
    const vertical = 'bottom';
    const horizontal = 'center';

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={3000}
            open={open}
            onClose={onClose}
            TransitionComponent={SlideTransition}
            className="snackbar"
        >
            <Alert className="warnigalert" severity="warning">
                {msg}
            </Alert>
        </Snackbar >

    );
}