import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/CallToAction/Breadcrumb/Breadcrumb';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const Contact = () => {
    return (
        <React.Fragment>
            <SEO title="LOTS | Contact Us" />
            <Header />
            <Breadcrumb
                image="images/background/contact.jpg"
                title="Get in touch with the LOTS team"
                content="Home"
                contentTwo="Contact Us"
            />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}
export default Contact;

