import { useState, useEffect, useRef } from "react";
import Tilt from 'react-parallax-tilt';
import { Link } from "react-router-dom";
import Parallax from 'parallax-js';
import SectionTitleTwo from '../SectionTitles/SectionTitleTwo';

const OperationOtsourcing = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        })

        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])

    return (
        <div className="section section-padding-bottom section-padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5 col-12" data-aos="zoom-in-right" data-aos-delay="400">
                        <div className="about-image-area">
                            <div className="about-image pt-lg-10">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/solution/outsource.jpg"} alt="" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo
                                title="Operations Outsourcing"
                            />
                            <div>We help organizations streamline their operations with automation, efficiency,
                                and effectiveness and deliver results through Intelligent Business Process Execution,
                                Empower your business with productivity and get things done. We help organizations
                                realize their full operations potential through efficient, digitized, and agile
                                operations solutions.

                            </div>
                            <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-2 mb-3" to={process.env.PUBLIC_URL + "/operation-outsourcing"}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperationOtsourcing;
