import React from 'react';
import SectionTitleTwo from '../../SectionTitles/SectionTitleTwo';

export const TechnologyDataDetails = () => {
    return (

        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Our expertise"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <div className="details-list">
                                        <li>Technology, Data & Cloud Architecture</li>
                                        <li>Software Engineering</li>
                                        <li>System Designs & Architecture</li>
                                        <li>Product Engineering</li>
                                        <li>Data Visualization & Data Stories</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Software engineering as a service"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>We believe in the art of developing technology solutions that help an organization deliver
                                        services and products. Servicing is a means to an end, the key success factor for an organization
                                        is an engineered solution that delivers business outcomes. We combine design, application engineering,
                                        automation and data engineering to develop custom solutions for all functions across the organization.
                                    </p>
                                    <p>We help clients build powerful databases, dashboards, and data
                                        engineering models to unlock strategic outcomes.
                                    </p>
                                    <p>
                                        We analyze business processes in real-time to understand how exactly your organization works.
                                        Process analysis provides opportunities
                                        for organizations to improve processes, cut cost and increase value through innovation and automation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Business data analytics and process mining"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        We are a company obsessed with data and the endless insights it brings. At LOTS, we do
                                        believe data is the new oil and will shape the future of economies and organizations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="How we do it"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h5 className="heading heading-h5">Develop requirements</h5>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                <p>
                                    Accurate solution requirements are fundamentals of successful engineered technology.
                                    We develop a detailed business requirement to define the proposed solution.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h5 className="heading heading-h5">Co-create</h5>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                <p>
                                    We set up design sessions with the client to co-create and design the look and feel of the technology.
                                    We focus on the minimalism, functionality, and beauty of every tech solution we build.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h5 className="heading heading-h5">Build and engineer</h5>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                <p>
                                    Through robust iteration and client engagement, we engineer the business technology focused on fit-for-purpose functionality.
                                    Our focus under technology and data solutions is developing the right solution within a reasonable timeframe.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-20 mt-12">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="digital-marketing" data-aos="fade-up">
                            <h5 className="heading heading-h5">Deploy and maintenance</h5>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                        <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                            <div className="inner">
                                <p>
                                    Upon successful client acceptance and sign-off, we deploy your technology across enterprise
                                    or business functions. We offer
                                    value added service like maintenance of the tech-solution focusing on
                                    continuous improvement and feature innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}