import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Industry from "./pages/Industry";
import Function from "./pages/Function";
import Solution from "./pages/Solution";
import ComingSoon from "./pages/ComingSoon";

// extra
import {
  StrategyDetails,
  OperationDetails,
  CustomerDetails,
  TechDataDetails,
  BondsFinancialsDetails,
} from "./pages/SolutionDetails";

// CSS File Here
import "aos/dist/aos.css";
import "./assets/scss/style.scss";

function App() {
  SwiperCore.use([Navigation, Autoplay]);

  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: false,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/industry"}`}
            component={Industry}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/function"}`}
            component={Function}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/solution"}`}
            component={Solution}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          {/*  */}
          <Route
            path={`${process.env.PUBLIC_URL + "/strategy-consulting"}`}
            component={StrategyDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/operation-outsourcing"}`}
            component={OperationDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/customer-interactive"}`}
            component={CustomerDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/tech-and-data"}`}
            component={TechDataDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/bonds-and-financials"}`}
            component={BondsFinancialsDetails}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
            component={ComingSoon}
          />
          <Route exact path={`/**`}>
            <Redirect to={`/`} />
          </Route>
        </Switch>
      </NavScrollTop>
    </Router>
  );
}

export default App;
