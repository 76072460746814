import React from 'react';
import SectionTitleTwo from '../../SectionTitles/SectionTitleTwo';

export const CustomerInteractiveDetails = () => {
    return (
        <div className="section section-padding-top section-padding-bottom">
            <div className="container">
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="How we do it"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        We use process and design thinking to tackle business
                                        and customer problems and identify new opportunities for improvement
                                    </p>
                                    <p>
                                        We use the above levers to help organizations design services and products
                                        to retain and delight their customers at every stage of interaction with
                                        their businesses.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Business design and innovation services"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <p>
                                        Business design is the human-centered approach to innovation.
                                        We design new businesses, products/services, and business models
                                        that deliver innovation across markets and value chains.
                                    </p>
                                    <p>We help organizations differentiate and articulate the unique value
                                        proposition, design competitive advantages so that they can defend and own
                                        their markets.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row  pb--80">
                    <div className="row mt-lg-20">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="digital-marketing" data-aos="fade-up">
                                <SectionTitleTwo
                                    title="Our focus areas for business design"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                            <div className="digital-marketing mt-lg-8 " data-aos="fade-up">
                                <div className="inner">
                                    <div className="details-list">
                                        <li>Viability (is there a business case?)</li>
                                        <li>Desirability (is it a want or need offering, how humane is this?)</li>
                                        <li>Integrity (what impact does it deliver to society and the customer?)</li>
                                        <li>Feasibility (can it be achieved with the right technology and processes?)</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}